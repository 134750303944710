import React, { useState } from 'react';

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="lg:pt-20" style={{maxHeight: "70vh", overflow:"scroll"}}>
      <div className="flex">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={`tab-button py-2 px-4 font-lilita ${
              activeTab === index
                ? 'border-b-2 border-black font-bold font-lilita'
                : 'opacity-50'
            }`}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="p-8">
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default Tabs;
