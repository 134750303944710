import React from 'react';
import Typed from "typed.js"
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import customerData from '../data/customer-data';
import icon from '../assets/icon.png';
import questions from '../assets/questions.png';
import rdv from '../assets/rdv.png';
import done from '../assets/done.png';
import solving from '../assets/solving.png';
import location from '../assets/location.png';
import checklist from '../assets/checklist.png';
import whyUs from '../assets/whyUs.png';

import "./../css/global.css";
import Tabs from '../components/Tab';

const Index = () => {

  const element = React.useRef(null);
  const tabs = [
    {
      title: 'À propos',
      content: <div className='text-left lg:text-xl'>
        <p><span className='font-lilita'>La Fabrique du Code</span> est bien plus qu'une entreprise de développement web. Nous sommes des artisans du numérique, des passionnés qui transforment des lignes de code en des expériences numériques uniques et innovantes.</p>
        <p>Fondée sur les piliers de l'excellence, de la créativité et de l'expertise, notre entreprise s'engage à repousser les limites de la conception web. Chaque projet est une toile vierge sur laquelle nous façonnons des solutions sur mesure, adaptées à vos besoins uniques.</p>
        <p>Notre équipe dévouée est composée de professionnels chevronnés, experts dans leur domaine, prêts à transformer vos idées en réalité. Que ce soit pour la création de sites web intuitifs, le développement d'applications novatrices ou la mise en place de stratégies digitales percutantes, nous mettons notre savoir-faire au service de votre succès.</p> 
        <p>Nous croyons en la fusion entre technologie et art, où chaque pixel et chaque ligne de code sont méticuleusement pensés pour offrir une expérience exceptionnelle à nos clients. Chez <span className='font-lilita'>La Fabrique du Code</span>, nous nous efforçons de créer des solutions qui transcendent les attentes, pour vous aider à prospérer dans le monde numérique en constante évolution.</p>
        <p>Rejoignez-nous dans cette aventure où le code devient une œuvre d'art, où la créativité rencontre la technologie, et où vos ambitions trouvent leur place au cœur de notre expertise.</p>
      </div>
    },
    {
      title: 'Transparence',
      content: <section class="text-left lg:text-xl">
        <p className='mb-3'>
          Chez <span className='font-lilita'>La Fabrique du Code</span>, la transparence est au cœur de notre fonctionnement. Nous croyons en l'importance d'une relation ouverte et honnête avec nos clients, partenaires et collaborateurs.
        </p>
        <ul className="list-disc pl-6 mb-3">
          <li><strong className='font-lilita'>Communication Ouverte :</strong> Nous favorisons une communication claire et directe à tous les niveaux.</li>
          <li><strong className='font-lilita'>Honnêteté et Intégrité :</strong> Nous valorisons l'intégrité dans toutes nos interactions.</li>
          <li><strong className='font-lilita'>Respect de la Confidentialité :</strong> Vos données et informations sensibles sont traitées avec le plus grand respect.</li>
          <li><strong className='font-lilita'>Écoute Active :</strong> Votre opinion compte. Nous valorisons vos retours et vos préoccupations.</li>
        </ul>
        <p>
          Nous sommes fiers de notre approche transparente et nous vous invitons à nous contacter pour toute question ou préoccupation. Nous croyons fermement que la transparence renforce la confiance mutuelle, élément essentiel de toute collaboration fructueuse.
        </p>
      </section>,
    },
    {
      title: 'Expertise',
      content: <section class="text-left lg:text-xl">
        <p class="mb-3">
          <span className='font-lilita'>La Fabrique du Code</span> se positionne comme un pionnier dans l'utilisation de technologies de pointe pour la création de solutions digitales. Notre expertise s'appuie sur une maîtrise avancée de divers outils et frameworks, notamment :
        </p>
        <ul class="link-menu-bis list-disc pl-6 mb-3">
          <li><a href='https://fr.react.dev/' className='font-lilita'>React :</a> Pour des interfaces utilisateur interactives et performantes.</li>
          <li><a href='https://www.gatsbyjs.com/' className='font-lilita'>Gatsby :</a> Pour des sites web rapides et hautement optimisés.</li>
          <li><a href='https://nextjs.org/' className='font-lilita'>Next.js :</a> Pour des applications web évolutives et dynamiques.</li>
          <li><a href='https://www.php.net/' className='font-lilita'>PHP :</a> Pour le développement backend robuste et évolutif.</li>
          <li><a href='https://symfony.com/' className='font-lilita'>Symfony :</a> Pour la création d'applications web puissantes et évolutives.</li>
        </ul>
        <p class="text-gray-700 leading-relaxed">
          Notre équipe de professionnels chevronnés excelle dans l'application de ces technologies pour offrir des solutions sur mesure à nos clients. Que ce soit pour la création de sites web réactifs, d'applications dynamiques ou de systèmes complexes, nous nous engageons à utiliser les meilleures pratiques pour répondre à vos besoins avec des solutions innovantes et fiables.
        </p>
      </section>,
    },
  ];

  const onClick = () => {
    navigator.clipboard.writeText("06 50 71 21 18")
  }

  React.useEffect(()=>{
    if(element.current){
      const options = {
        strings: [
          "Construisez votre présence digitale",
        ],
        typeSpeed: 50,
        backSpeed: 40,
        smartBackspace: true,
        loop: true
      };
      const typedString = new Typed(element.current, options);
  
      return () => {
        typedString.destroy();
      };
    };
  }, []);

  return(
    <Layout>
      <section className="first-section py-20 lg:h-screen flex items-center" id="home">
        <div className="container px-3 lg:flex lg:px-8">
          <div className="text-center lg:text-left lg:w-2/3">
            <h1 className="font-lilita text-4xl lg:text-5xl xl:text-6xl font-bold leading-none title uppercase">
              Construisez votre présence digitale
            </h1>
            <p className="content-title text-xl lg:text-2xl lg:mt-4 font-light">
              Les sites web de La Fabrique du Code portent l'empreinte de l'artisanat numérique, gage de qualité et de savoir-faire.
            </p>
            <p className="mt-8 md:mt-12">
              <Button className='principal-button font-lilita' size="lg" onClick={() => onClick()}>
                06 25 98 22 25
              </Button>
            </p>
            <br/>
            <p className="mt-4 text-gray-600 devis">Expertise gratuite</p>
          </div>
          <div className="w-0 lg:block lg:w-1/2 lg:ml-16">
            <img src={icon} alt="icon"/>
          </div>
        </div>
      </section>
      <section id="how" className="second-section h-screen pt-24">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold font-lilita">Notre expertise</h2>
          <Tabs tabs={tabs}/>
        </div>
      </section>
      <section id='needs' className='third-section lg:py-0 lg:py-24 h-screen flex items-center' style={{height: "120vh"}}>
        <div className="container mx-auto text-center">
          <h3 className="text-3xl lg:text-5xl font-semibold font-lilita">Pour qui ?</h3>
          <div className="lg:pt-20 text-left lg:text-xl content-needs">
              <p className='mb-3'>
              Que vous soyez un entrepreneur ambitieux, une entreprise établie, un créateur indépendant, une startup en plein essor ou même un passionné souhaitant partager votre histoire en ligne, nous sommes là pour vous.
              </p>
              <ul className="list-disc pl-6 mb-3">
                <li><strong className='font-lilita'>Entrepreneurs et Startups :</strong> Transformez vos idées en réalité digitale. Créez une présence en ligne dynamique pour promouvoir votre entreprise ou lancer votre startup avec succès.</li>
                <li><strong className='font-lilita'>Professionnels Créatifs :</strong> Artistes, écrivains, musiciens, designers - faites briller votre talent en ligne avec un site web qui reflète votre créativité et votre personnalité.</li>
                <li><strong className='font-lilita'>Petites et Moyennes Entreprises :</strong> Renforcez votre présence sur le web. Que vous soyez une boutique locale, un cabinet d'experts ou un service en ligne, nous sommes là pour développer votre visibilité.</li>
                <li><strong className='font-lilita'>Associations et Organisations à But Non Lucratif :</strong> Partagez votre cause, mobilisez des soutiens et renforcez votre impact grâce à un site web engagé et informatif.</li>
                <li><strong className='font-lilita'>Tout Passionné du Web :</strong> Que vous ayez un projet personnel, une idée novatrice ou simplement l'envie d'être présent en ligne, notre équipe est là pour vous guider.</li>
              </ul>
          </div>
        </div>
      </section>
      <section id='contact' className='fourth-section h-screen pt-24 flex items-center'>
        <div className="container mx-auto text-center">
          <h3 className="text-3xl lg:text-5xl font-semibold font-lilita">Nous contacter</h3>
          <p className="mt-8 text-xl font-light">
            Pour toute demande d'information, de devis ou de collaboration, n'hésitez pas à nous contacter. Notre équipe est là pour répondre à vos questions et vous accompagner dans votre projet digital.
          </p>
          <p class="mt-8 text-xl font-light link-menu-bis list-disc pl-6 mb-3">
            Adresse e-mail : <a href="mailto:codecrafters83@gmail.com" class="font-lilita">codecrafters83@gmail.com</a>
          </p>
          <p className='mt-8 text-xl font-light'>
            Ou par téléphone :
          </p>
          <p className="mt-8">
            <Button size="xl" onClick={() => onClick()}>06 50 71 21 18</Button>
          </p>  
        </div>
      </section>
    </Layout>
  );
};

export default Index;
